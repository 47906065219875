import type { ReactNode } from "react";
import type { ColumnFilterInputType } from "./graphql.gen/graphql.ts";

/** Meta-properties about a field belonging to a {@link TItem}, which is of type {@link TValue}. */
export interface FieldConfig<TItem, TValue> {
    /** Accessor to get the value of the field from the item */
    get: (obj: TItem) => TValue;
    render?: React.FC<{ value: TValue; item: TItem }>;

    //#region Look-and-feel properties
    title: ReactNode;
    /** Title when editing the field (in forms) */
    editTitle?: ReactNode;

    /** Add a "copy to clipboard" button to the field */
    copyable?: boolean;
    /** Show this field in summary tables by default */
    inTable?: boolean;
    align?: "text-left" | "text-center" | "text-right";

    /** Class name to add to `<td>` elements */
    cellClassName?: string;
    //#endregion
}

/** Field that has a unique ID */
export interface IdFieldConfig<TItem, TValue> extends FieldConfig<TItem, TValue> {
    id: string;
}

/** Field that can be ordered/filtered via GraphQL */
export interface GqlFieldConfig<TItem = unknown, TOrderFields extends string = string, TValue = unknown>
    extends IdFieldConfig<TItem, TValue> {
    /** ID of the field in the GraphQL order enum */
    order?: TOrderFields;
    /** Hidden by default (need to show explicitly from column menu) */
    hidden?: boolean;
    filter?: FilterComponent;
}

/**
 * Convenience type for field definitions (to have better auto-complete and type errors).
 *
 * @example const myFields = { ... } satisfies FieldsOf<MyType>;
 */
export type FieldsOf<TItem> = Record<string, FieldConfig<TItem, any>>;

export type GqlFieldsOf<TItem, TOrderFields extends string> = GqlFieldConfig<TItem, TOrderFields, any>[];

//#region Filter types

/** Common interface implemented by all GraphQL `*Filter` schemas, value only */
export interface GqFilterValue {
    type?: ColumnFilterInputType;
    value?: string;
    value2?: string;
    values?: string[];
}

/** Common interface, filter value for a single field */
export interface GqFieldFilter<TOrderFields extends string = string> extends GqFilterValue {
    field: TOrderFields;
}

export type FilterComponent = React.FC;

//#endregion

/** Utility function to automatically infer types for a {@link FieldConfig} */
export function field<TItem, TValue>(
    title: ReactNode,
    get: (obj: TItem) => TValue,
    options?: Partial<FieldConfig<TItem, TValue>>,
): FieldConfig<TItem, TValue> {
    return { title, get, ...options };
}

/** Utility function to infer types for a {@link GqlFieldConfig}.
 *
 * (otherwise TypeScript gets confused when different types of fields are mixed in a single object.)
 */
export function gf<TItem, TOrderFields extends string, TValue>(options: GqlFieldConfig<TItem, TOrderFields, TValue>) {
    return options;
}

/** "Map" over a field config, transforming the object it operates on. */
export function mapField<TNewItem, TItem, TValue>(
    { render, get, ...field }: FieldConfig<TItem, TValue>,
    mapFn: (obj: TNewItem) => TItem,
    options?: Partial<FieldConfig<TNewItem, TValue>>,
): FieldConfig<TNewItem, TValue> {
    return {
        ...field,
        get: (obj: TNewItem): TValue => get(mapFn(obj)),
        render: render ? ({ value, item }) => render({ value, item: mapFn(item) }) : undefined,
        ...options,
    };
}
